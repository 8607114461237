import { removeToken } from '@/utils/auth';
import { getMenuAPI } from '@/api/permission/menuAPI';
import { userBelongCorporationInfoAPI } from '@/api/corporationAPI';
import { getUserListSelectAPI } from '@/api/userAPI';
import { getCloudClientInfoAPI } from '@/api/cloud/clientAPI';
import { toast } from '@/utils/common';

export default {
  namespaced: true,
  state: {
    clientName: '', // 当前用户属于哪个公司
    navList: [], // 菜单一级列表
    userSelectList: [],
    user_id: 0, // 用户id 用户修改密码
    clientId: '',
  },
  mutations: {
    set_user_info(state, value = {clientName: {}, navList: [], clientId: ''}) {
      state.clientName = value.clientName;
      state.navList = value.navList;
      state.clientId = value.clientId;
    },
    set_user_select(state, value) {
      state.userSelectList = value;
    },
    set_user_id(state, value) {
      state.user_id = value;
    }
  },
  actions: {
    async SET_USER_INFO({commit}) {
      const userInfo = await userBelongCorporationInfoAPI();
      const menus = await getMenuAPI();
      const cloud = await getCloudClientInfoAPI();
      // 如果该用户没有分配公司 不允许登录
      if(!userInfo.result) {
        removeToken();
        commit('set_user_info');
        toast('请给该用户分配公司/组织后再尝试登录！', 'error')
      } else {
        // 选择语言发生变化且不为定制海外环境 则切换缓存语言
        if (userInfo.result.lang.indexOf(localStorage.lang)<0 && process.env.NODE_ENV!=='overseas') {
          localStorage.lang = userInfo.result.lang;
          location.reload();
        }
        // 未开通云开发服务则不显示
        if (!cloud.result?.hasCloudDev) {
          menus.result = menus.result.filter(item=>item.vueUrl!='/iot/cloud');
        }
        commit('set_user_info', {clientName: userInfo.result, navList: menus.result, clientId: cloud.result.secretInfo?.clientId})
      }
    },
    // 退出登录
    LOGIN_OUT({ commit }) {
      // 清除cookie中的token
      removeToken();
      // 清除当前用户的基本信息
      commit('set_user_info');
      // 清除用户id
      commit('set_user_id', 0)
    },
    // 获取用户下拉框列表
    async GET_USER_SELECT({state, commit}) {
      if(!state.userSelectList.length) {
        const res = await getUserListSelectAPI();
        commit('set_user_select', res.result);
      }
    }
  },
}