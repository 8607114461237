// 客户管理
import service from '@/request/index';
const baseURL = '/cloud-client'

/**
 * 获取当前登录用户的云开发密钥信息
 */
export function getCloudClientInfoAPI() {
  return service({
    method: 'get',
    url: `${baseURL}/info`
  })
};

/**
 * 客户密钥重置
 * @data clientId
 */
export function resetCloudClientAPI(data) {
  return service({
    method: 'post',
    url: `${baseURL}/reset`,
    data
  })
};