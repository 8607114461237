import zhLocale from 'element-plus/lib/locale/lang/zh-cn';

const zh = {
  ...zhLocale,
  /////////////////// 基本通用 /////////////////
  common: {
    add: "添加",
    delete: "删除",
    update: "更新",
    confirm: "确定",
    cancel: "取消",
    query: '查询',
    empty: '清空',
    copy: '复制',
    tipContent: "提示内容",
    success: "成功！",
    unknowErr: '请求失败！',
    pleaseInput: "请输入",
    noEmpty: "不能为空",
    none: "无",
    have: '有',
    disable: '禁用',
    normal: '正常',
    expired: '过期',
    confirmDelete: "确认删除该项吗？",
    confirmAdd: "确认添加该项吗？",
    confirmEditPsw: '确认该用户修改密码吗？',
    confirmResetPsw: '确认该用户重置密码吗？',
    confirmEditStatus: '确认修改用户状态吗?',
    copyText: "复制成功，剪切板内容：",
    updateSuccess: '更新成功！',
    updateFailt: '更新失败！',
    all: '全部',
    download: '下载',
    downloadTemplate: '模版下载',
    pleaseSelect: '请选择',
    default: '默认',
    day: '日',
    hour: '小时',
    month: '月',
    week: '周',
    oneHour: '1小时',
    Hour24: '24小时',
    day7: '7天',
    day30: '30天',
    year10: '十年',
    exportData: '导出数据',
    multipleExportData: '批量导出数据',
    uploadExcelWarn: '请上传Excel文件',
    time: '时间',
    startTime: '开始时间',
    endTime: '结束时间',
    data: '数据',
    yes: '是',
    no: '否',
    selectMonth: '选择月',
    selectYear: '选择年',
    Jan: '1月',
    Feb: '2月',
    Mar: '3月',
    Apr: '4月',
    May: '5月',
    Jun: '6月',
    Jul: '7月',
    Aug: '8月',
    Sept: '9月',
    Oct: '10月',
    Nov: '11月',
    Dec: '12月',
    nearly10: '近10条',
    s: '秒',
    ms: '毫秒',
    min: '分钟',
    chinese: '简体中文',
    english: '英文',
    polish: '波兰语',
    german: '德语'
  },
  
  /////////////////// 按钮文案 /////////////////
  buttonText: {
    add: "添加",
    save: "保存",
    reset: "重置",
    delete: "删除",
    edit: "编辑",
    set: '设置',
    detail: '详情',
    addProduct: '添加产品',
    search: '搜索',
    loadMore: '加载更多',
    position: '位置',
    toggleStatus: '切换状态',
    syncDev: "同步设备信息",
  },

  /////////////////// 表单文案 /////////////////
  formText: {
    operation: '操作',
    parentCorporation: '上级公司(组织)',
    corporation: '公司(组织)',
    PlatformName: '平台名称',
    address: '地址',
    remake: '备注',
    email: '邮箱',
    createTime: '创建时间',
    updateTime: '更新时间',
    expirationTime: '到期时间',
    resetTime: '重置时间',
    resetBy: '重置人',
    lang: '地区语言',
    lastLoginTime: '最后登录时间',
    memberName: '成员姓名',
    username: '用户名',
    cropAdmin: '公司管理员',
    corpInstaller: '公司安装工',
    name: '名称',
    status: '状态',
    phone: '联系方式',
    mPhone: '电话号码',
    controlRight: '设备控制权限',
    success: '成功',
    fail: '失败',
    latAndlon: '(纬度, 经度)',
    showIcon: '显示图标',
    showClientName: '显示公司自定义平台名称',
    miniAdmin :'小程序管理端权限',

    // 产品
    product: '产品',
    productName: '产品名称',
    productId: '产品ID',
    productType: '产品类型',
    platformType: '平台类型',
    readOnly: '只读',
    writeOnly: '只写',
    writeRead: '读写',
    devAudit: '设备审核',

    // 设备
    deviceOnline: '设备在线',
    deviceOffline: '设备离线',
    deviceUnactive: '设备未激活',
    deviceDisable: '设备禁用',
    deviceUnauthorized: '设备未授权',
    deviceNameOrRemake: '设备名称/备注名称',
    remakeName: '备注名称',
    deviceId: '设备ID',
    deviceName: '设备名称',
    deviceStatus: '设备状态',

    numberType: '数字型',
    min: '最小值',
    max: '最大值',
    step: '步长',
    multiple: '倍数',
    unit: '单位',
    unitName: '单位名称',
    enumType: '枚举型',
    enumItem: '枚举项',
    param: '参数',
    paramName: '参数名',
    paramUnit: '参数单位',
    paramDescription: '参数描述',
    booleanType: '布尔型',
    faultType: '故障型',
    faultItem: '故障项',
    valueRange: '取值范围',
    stringType: '字符型',
    
    // sn
    snCode: 'SN码',
    noDevExists: '不存在设备',
    devExists: '已存在设备',
    devAdded: '已添加设备',
    scanTime: '扫码时间',

    // wx授权码
    used: '已使用',
    notUsed: '未使用',
    authCode: '授权码',
    corporationId: '公司/组织ID',
    creatorId: '创建人ID',
    registerId: '注册人ID',
  },

  /////////////////// 校验规则文案 /////////////////
  rulesText: {
    corporationNoEmpty: '公司(组织)不能为空',
    clientNameNoEmpty: '平台名称不能为空',
    addressNoEmpty: '地址不能为空',
    memberNameNoEmpty: '成员姓名不能为空',
    productNoEmpty: '产品不能为空',
    nameNoEmpty: '名称不能为空',
    newPswNoEmpty: '新密码不能为空',
    passwordNoEmpty: '密码不能为空',
    agiainPswNoEmpty: '请输入确认密码',
    samePswNoEmpty: '两次密码输入不一致，请重新输入',
    usernameNoEmpty: '用户名不能为空',
    childAccoutCountNoEmpty: '子账号总数不能为空',
    roleNameNoEmpty: '角色名称不能为空',
    rightCodeNoEmpty: '权限码不能为空',
    orderNoEmpty: '顺序不能为空',
    logTitleNoEmpty: '日志标题不能为空',
    logContentNoEmpty: '日志内容不能为空',
    placeEnterValue: '请输入要修改的内容',
    placeEnterDeviceName: '请输入设备名称或设备ID查询',
    publicIdentifyNoEMpty: '公共数据点不能为空',
    dataPointNoEmpty: '数据点不能为空',
    dataPointNameNoEmpty: '数据点名称不能为空',
    modelNoEmpty: '模式值不能为空',
    timeOpenPointHourNoEmpty: '定时开数据点(小时)不能为空',
    timeOpenPointMinNoEmpty: '定时开数据点(分钟)不能为空',
    timeClosePointHourNoEmpty: '定时关数据点(小时)不能为空',
    timeClosePointMinNoEmpty: '定时关数据点(分钟)不能为空',
    enginnerAddressNoEmpty: "工程地址不能为空",
    engineerNameNoEmpty: '工程名称不能为空',
    engineerTimeRangeNoEmpty: '工程起止时间不能为空',
    engineerStatusNoEmpty: '工程状态不能为空',
    selectAddDevice: '请选择要添加的设备',
    productIdNoEmpty: '产品ID不能为空',
    productNameNoEmpty: '产品名称不能为空',
    productTypeNoEmpty: '产品类型不能为空',
    platformTypeNoEmpty: '平台类型不能为空',
    readWriteTypeNoEmpty: '读写类型不能为空',
    belongProductNoEmpty: '所属产品不能为空',
    identifierNoEmpty: '标识符不能为空',
    snNoEmpty: '序列号不能为空',
    dataTypeNoEmpty: '数据类型不能为空',
    minNoEmpty: '最小值不能为空',
    maxNoEmpty: '最大值不能为空',
    stepNoEmpty: '步长不能为空',
    multipleNoEmpty: '倍数不能为空',
    paramDescriptNoEmpty: '参数描述不能为空',
    paramNoEmpty: '参数不能为空',
    paramValueNoEmpty: '参数值不能为空',
    paramNameNoEmpty: '参数名不能为空',
    identifierNameNoEmpty: '标识符名称不能为空',
    usernameNoExist: '用户名不存在',
    enterAnInteger: '请输入整数',
    enterValidNumber: '请输入有效的数字',
    enterInt1to50: '数字必须大于等于1且小于等于50',
    devIdNoEmpty: '设备ID不能为空',
  },

  /////////////////// 登录页面 /////////////////
  login: {
    chicoPlatformName: "物联网管理平台",
    username: "用户名",
    password: "密码",
    remberpass: "记住密码",
    forgetpass: "忘记密码?",
    login: "登录",
    fillusername:"用户名不能为空",
    fillpassword:"密码不能为空",
    passNoSpaces:"密码不能包含空格",
    passLengthMoreThan8:"密码长度不能小于8位",
    passLengthMoreThan16:"密码长度不能大于16位",
    userLengthLessThan4: '用户名不能小于4位',
    userLengthMoreThan10: '用户名不能大于10位',
    noSpecialSymbols: "不能包含特殊字符，以及大写字母",
    noAccess: "无访问权限",
    loginDate:"登录过期",
    errAdmin:"请求错误，联系管理员",
    pleaseLogin: "请先登录！",
    loginAgain: "请勿重复登录！",
    errOldPass:"原密码不正确",
    fillconfirmpass:"两次密码输入不一致",
    fillOldPass:"原密码不能为空",
    fillNewPass:"新密码不能为空",
    oldPass:"原密码",
    newPass:"新密码",
    confirmNewPass:"确认新密码",
    editPasswordTitle: "修改密码",
    oldPasswordPlaceholder: "请输入旧密码",
    newPasswordPlaceholder: "请输入新密码",
    comfirlPasswordPlaceholder: "请确认新密码",
    loginremberpass: '记住密码',
    name: '姓名',
    loginSuccess: '登录成功',
    loginOutSuccess: '退出登录成功',
  },

  /////////////////// 注册页面 /////////////////
  register: {
    customPlatformName: "自定义平台名称",
    email: "注册邮箱",
    verifiCode: "验证码",
    getVerifiCode: "获取验证码",
    register: "注册",
    registerSuccess: "注册成功",
    logTips: "可前往物联网管理平台登录",
    goLogin: "前往登录",
    linkInvaild: "注册链接失效",
    linkTips: "请联系管理员重新获取链接",
    errorEmail: "请输入正确的邮箱",
    registerTitle: "物联网管理平台注册",
  },

  /////////////////// 头部组件 /////////////////
  header: {
    personCenter: "个人中心",
    support:"技术支持",
    updateLog:"更新日志",
    editPassword: "修改密码",
    loginOut: "退出登录",
  },

  ///////////////////   权限   /////////////////
  /////////////////// 菜单列表 /////////////////
  menuList: {
    menuManage: "菜单列表管理",
    addMenu: "添加菜单",
    editMenu: "编辑菜单",
    chooseMenuEdit: "从左侧菜单列表选择一项，进行编辑",
    confirmDeleteMenu: "确定删除该菜单吗？",
    menuName: "菜单名称",
    englishName: "英文名称",
    polishName: "波兰名称",
    germanName: "德语名称",
    higherMenu: "上级菜单",
    pageUrl: "页面链接",
    rankPosition: "排序位置",
    icon: "图标",
    menuList: '菜单列表',
  },
  /////////////////// 角色权限 /////////////////
  roleAuthorization: {
    roleRightManage: "角色权限管理",
    addRole: "添加角色",
    distributionMenu: "分配菜单",
    confirmPermission: "确认权限",
    selectRoleHasMenu: "从左侧角色列表选择一项，查看该角色对应的菜单权限。",
  },
  /////////////////// 公司/组织 /////////////////
  corporation: {
    corporationList: '公司(组织)列表',
    corporationManage: '公司(组织)管理',
    corporationName: '公司(组织)名称',
    addCorporation: '添加公司(组织)',
    addUserCorporation: '添加经销商',
    editCorporation: '编辑公司(组织)',
    backCorporationList: '返回公司列表',
    memberInfo: '成员信息',
    addMember: '添加成员',
    editMember: '编辑成员',
    productInfo: '产品信息',
    addProduct: '添加产品',
    devInfo: '设备信息',
    snInfo: 'SN码信息',
    wxAuthCodeInfo: '微信授权码信息',
    addAuthCode: '添加授权码',
    batchAddAuthCode: '批量添加授权码',
    batchNewCodeQty: '请输入新增授权码数量',
    addAuthCodeConfirm: '确认增加微信授权码？',
    exportUnusedAuthCode: '导出未使用授权码',
    wxUserInfo: '微信用户信息',
    editUser: '编辑用户',
    addExistMember: '添加已存在用户',
    addNewMember: '添加新用户',
    editCorporationIcon: '编辑公司(组织)图标',
    iconPreview: '图标预览',
    getRegistrationLink: '获取注册链接',
    companyLogo: '公司图标',
  },
  /////////////////// 用户管理 /////////////////
  user: {
    addUser: '添加用户',
    lastLogin: '最后登录时间',
    roleName: '角色名称',
    editPsw: '修改密码',
    resetPsw: '重置密码',
    resetSuccess: '重置成功',
    newPsw: '新密码',
    agiainPsw: '确认密码',
    password: '密码',
    childAccoutCount: '子账号总数',
    usernameOrname: '用户名/用户姓名',
    backUserManage: '返回用户管理',
    platformRole: '平台角色',
    roleRelationship: '角色关系',
    rightCode: '权限码',
    order: '顺序',
  },
  /////////////////// 子账号管理 /////////////////
  subAccout: {
    addSubAccout: '添加子账号',
    canAddSubAccoutNum: '可添加子账号总数',
    existSubAccoutNum: '已有子账号数',
    surplusAccoutNum: '剩余可添加子账号数',
    attention: '注意：该操作无法恢复！请慎重操作！',
    confirmAccout: '输入当前账号密码，确认删除该子账号',
    confirmAccoutSubmit: '请输入子账号密码再提交',
    backAccoutMange: '返回子账号管理',
    disbuteDevice: '分配设备',
    disbuteDeviceGroup: '分配设备组',
    confirmEmptyItem: '确定要清空所有选项吗？',
    deviceIdQuery: '输入设备ID查询',
    deviceNameQuery: '输入设备名称查询',
  },
  /////////////////// 经销商管理 /////////////////
  dealer: {
    dealerManage: '经销商管理',
  },

  ///////////////////   系统   /////////////////
  /////////////////// 登录日志 /////////////////
  loginLog: {
    loginLog: '登录日志',
    selectUser: '请选择用户',
    loginIp: '登录IP',
    loginMode: '登录方式',
    loginTime: '登录时间',
  },
  /////////////////// 更新日志 /////////////////
  uploadLog: {
    uploadLog: "更新日志",
    addUploadLog: '新增更新日志',
    title: '标题',
    logContent: '日志内容'
  },
  /////////////////// 设备日志 /////////////////
  deviceLog: {
    deviceLog: '设备日志',
    platform: '平台',
    aliPlatform: '阿里平台',
    aliWeChat: '阿里微信',
    tuyaPlatform: '涂鸦',
    ali: '阿里云',
    yiYuan: '移远',
    oerationContent: '操作内容',
    oerationTime: '操作时间',
    oerationPerson: '操作人员',
    logType: '日志类型',
    dataPoint: '数据点名称',
    setValue: '设置值：',
    sendOrder: '下发指令',
    confirmSendOrder: '确定下发该指令吗?',
    dataName: '数据名称',
    dataList: '数据列表',
    selectDataPointAnaly: '请选择数据点进行分析',
    selectDataPointAnalyAndChart: '请选择数据点进行分析，查看数据图表',
    unitNum: '机组号',
    analyze: '分析',
  },

  ///////////////////   个人中心   /////////////////
  personCenter: {
    editName: '编辑姓名',
    editPhone: '编辑联系方式',
    editEmail: '编辑邮箱',
    editAddress: '编辑地址'
  },

  ///////////////////   首页   /////////////////
  front: {
    dataAnalyze: '数据分析',
    noData: '暂无数据',
    productList: '产品列表',
    productTotal: '产品总数',
    deviceTotal: '设备总数',
    onlineDeviceTotal: '在线设备总数',
    faultTotal: '故障总数',
    activeData: '激活数据',
    onlineData: '在线数据',
    scanTotal: '扫码添加设备数'
  },

  ///////////////////   设备   /////////////////
  ///////////////////   设备管理   /////////////////
  deviceManage: {
    deviceList: '设备列表',
    lastOnlineTime: '最后上线时间',
    LonLat: '经纬度',
    ip: 'IP地址',
    dataPointList: '数据点列表',
    childAccoutList: '子账号列表',
    runStatus: '运行状态',
    paramGroup: '参数组',
    dataAnaly: '数据分析',
    dataAnalyNew: '数据分析 (新)',
    backDeviceList: '返回设备列表',
    category: '品类',
    region: '地区',
    iotCard: '物联网卡号',
    image: '图标',
    multipleUnit: '多机组',
    unitNum: '多机组数量',
    value: '值',
    valueAndUnit: '值/单位',
    dataSpecs: '功能点属性',
    funcPoint: '功能点',
    currentStatus: '当前状态',
    setValue: '设定值',
    dataDescirpt: '数据点描述',
    showIdentifier: '显示标识符',
    show: '显示',
    hide: '隐藏',
    elecAnaly: '电量分析',
    powerConsump: '耗电量(kw/h)',
    on: '开启',
    off: '关闭',
    sortBydrag: '拖拽排序',
    bigDataLineChart: '大数据量折线图',
    saveAsImage: '保存为图片',
    restore: '还原',
    dataZoom: '区域缩放',
    dataZoomBack: '区域缩放还原',
    fullScreen: '全屏',
    timeRangeNoEmpty: '时间范围不能为空',
    timeLimitWarn: '查询数据时间限近30天内且起止时间跨度不超过7天。',
    exceeds7days: '起止时间跨度超过7天',
    exceeds30days: '查询数据时间仅限近30天内',
    thisTimeNoData: '该时间段不存在数据',
    timeranularity: '时间粒度',
    queryTime: '查询用时',
    all: '全选',
    historicalData: '历史数据',
    exportAllData: '导出全部数据点',
    region: '地区',
    address: '详细地址',
    batchSetLabel: '可批量选择设备及输入设备地区地址设置',
    batchSetBtn: '批量设置设备位置',
    confirmUpdateAddress: '确认更新设备地址?',
    selectedDevice: '已选中设备',
    flowExpiry: '流量卡到期时间'
  },
  ///////////////////   产品管理   /////////////////
  productManage: {
    productList: '产品列表',
    addProduct: '添加产品',
    productType: '产品类型',
    backProductList: '返回产品列表',
    dataDic: '数据字典',
    deviceDataConfig: '设备列表数据点配置',
    paramGroupConfig: '参数组配置',
    belongProject: '所属项目',
    importDictConfig: '导入数据字典配置文件',
    detailInfo: '详情信息',
    sn: '序列号',
    identifier: '标识符',
    identifierName: '标识符名称',
    readWriteType: '读写类型',
    dataType: '数据类型',
    clickUpload: '点击上传文件',
    fileDrop: '把文件拖拽到里面，或者',
    addParamGroup: '添加参数组配置',
    regularcollectData: '定时采集数据点',
    addregularcollectData: '添加定时采集数据点',
    regularCollection: '定时采集',
    regularCollectionDisabled: '定时采集数据点功能已关闭',
    regularCollectionEnabled: '定时采集数据点功能已开启',
    allowUnauthBind: '移动端允许未授权用户绑定',
    cancelAllowUnauthBind: '移动端取消允许未授权用户绑定',
    allowUnauthBindConfirm: '确认允许未授权用户绑定吗？',
    cancelAllowUnauthBindConfirm: '确认取消允许未授权用户绑定吗？',
    importRemarks: '导入备注',
    productRefreshSuccess: '产品设备信息同步成功',
    importDiyFault: '导入自定义故障配置',
    autoRelate: '自动关联',
    faultTemplateDownload: '故障模版下载',
    faultConfigExport: '故障配置导出',
  },
  ///////////////////   小程序配置   /////////////////
  miniProgram: {
    miniProgram: '小程序配置',
    dataPointMap: '数据点映射',
    timingControl: '定时数据点',
    factoryParam: '工厂参数',
    addFuncPoint: '添加功能点',
    publicDataIdentify: '公共数据点标识',
    dataPointName: '数据点名称',
    dataPoint: '数据点',
    modelValue: '模式值',
    switch: '开关',
    model: '模式',
    heatTemp: '制热温度',
    coolTemp: '制冷温度',
    currentTemp: '当前温度',
  },
  ///////////////////   定时数据点   /////////////////
  timeControl:{
    addTimePoint: '添加定时数据点',
    timeOpenPointHour: '定时开数据点(小时)',
    timeOpenPointMin: '定时开数据点(分钟)',
    timeClosePointHour: '定时关数据点(小时)',
    timeClosePointMin: '定时关数据点(分钟)',
    timeOpenHour: '定时开(小时)',
    timeOpenMin: '定时开(分钟)',
    timeCloseHour: '定时关(小时)',
    timeCloseMin: '定时关(分钟)',
  },
  ///////////////////   工厂参数   /////////////////
  factoryParam: {
    addFactortParam: '添加工厂参数',
  },
  ///////////////////   故障管理   /////////////////
  faultMange: {
    faultMange: '故障管理',
    faultList: '故障列表',
    productIdOrPointName: '产品ID/数据点名称',
    faultPoint: '故障数据点',
    faultCode: '故障码',
    faultNum: '故障数量',
    faultTime: '故障时间',
    diyFaultPoint: '自定义故障数据点',
    addDiyFaultPoint: '新增自定义故障数据点',
    faultName: '故障名称',
    faultValue: '故障值',
    faultStatus: '故障状态',
    chooseRemake: '备注：点击当前行即为选中该数据点！',
    completeFaultInfo: '故障值信息完善',
    configDeviceListDataPoint: '设备列表数据点配置',
    subFault: '开启设备故障推送',
    subSuccess: '开启推送成功',
    subFail: '开启推送失败',
    unSubSuccess: '关闭推送成功',
    unSubFailt: '关闭推送失败',
    openSub: '开启推送',
    closeSub: '关闭推送',
    notEliminated: '未消除',
    eliminated: '已消除'
  },
  ///////////////////   工程管理   /////////////////
  engineerManage: {
    engineerList: '工程管理列表',
    addEngineer: '添加工程',
    notStart: '未开始',
    underway: '进行中',
    finish: '已结束',
    engineerName: '工程名称',
    engineerTimeRange: '工程起止时间',
    startTime: '开始时间',
    endTime: '结束时间',
    enginnerAddress: '工程地址',
    status: '工程状态',
    creator: '创建者',
    backEngineerList: '返回工程列表',
    deviceOperate: '设备操作',
    addDevice: '添加设备',
    ownProduct: '所属产品',

  },
  ///////////////////   设备归属   /////////////////
  deviceOwnership: {
    deviceOwnership: '设备归属',
  },
  ///////////////////   设备审核   /////////////////
  deviceAudit: {
    deviceAudit: '设备审核',
    imei: 'IMEI码',
    customerName: '客户名称',
    customerPhone: '客户电话',
    installLocationTotalName: '安装地区',
    installAddress: '安装地址',
    installTime: '安装时间',
    auditDetail: '审核详情',
    inProgress: '审批中',
    approved: '审批通过',
    rejected: '已驳回',
    submitted: '提交审批',
    approve: '通过审批',
    reject: '审批驳回',
    audit: '审核',
    opinion: '审核意见',
    dealerName: '经销商名称',
    installImages: '安装图片',
    auditRecords: '审核记录',
  },
  ///////////////////   云开发   /////////////////
  ///////////////////   客户管理   /////////////////
  clientManagement: {
    clientManagement: '客户管理',
    clientId: '客户ID',
    corporationName: '公司名称',
    clientSecret: '客户密钥',
    confirmReset: '确定重置密钥吗？',
    serviceOverview: '服务概况',
  },
  ///////////////////   API管理   /////////////////
  apiManagement: {
    apiManagement: 'API管理',
    apiName: 'API名称',
    apiAddr: 'API访问地址',
    apiDocUrl: 'API文档地址',
    timeRange: '时间范围',
    totalCalls: '总调用次数',
    failedCalls: '调用失败次数',
    failureRate: '失败率',
    totalMsg: '获取消息总数',
    apiRequest: 'API 请求统计',
    apiDetail: 'API 请求详情',
    deviceStatistics: '设备消息统计',
  },
  ///////////////////   流量卡   /////////////////
  simCard: {
    simCard: '流量卡',
    companyManage: '企业管理',
    addCompany: '新增企业',
    appKey: '应用键',
    secret: '密钥',
    companyId: '企业ID',
    companyName: '企业名称',
    billingGroup: '计费组',
    cardList: '卡列表',
    bgId: '计费组ID',
    billingCode: '计费组编码',
    carrier: '运营商',
    setMeal: '套餐类型',
    cardType: '卡类型',
    productCode: '产品编码',
    isFlowShare: '流量共享',
    isShare: '共享',
    notShare: '非共享',
    useFlow: '已用流量（MB）',
    sumFlow: '总流量（MB）',
    residualFlow: '剩余流量（MB）',
    billingUseFlow: '流量池已用流量（MB）',
    billingSumFlow: '流量池总流量（MB）',
    billingResidualFlow: '流量池剩余流量（MB）',
    serviceCycle: '服务周期',
    status: 'SIM卡状态',
    pauseReason: '停机原因',
    allotDate: '出库日期',
    activeStatus: '激活状态',
    openDate: '开卡日期',
    activeDate: '激活日期',
    expiryDate: '到期日期',
    monthFlow: '当月已用流量(MB)',
    flow: '当前计费周期内已用流量(MB)',
    gprsStatus: 'GPRS状态',
    realnameStatus: '实名认证',
    cardSum: '卡总数',
    notActivated: '未激活',
    actived: '激活',
    unknown: '未知',
    other: '其他',
    stopped: '停机', 
    preCancel: '预销号',
    canceled: '销号',
    pendActive: '待激活',
    active: '正常',
    activeTesting: '测试期正常',
    stoppedTesting: '测试期停机',
    suspended: '停机保号',
  },
  ///////////////////   路由名称   /////////////////
  routeName: {
    login: '登录页',
    homePage: '后台首页',
    updateLogs: '更新日志',
    personalCenter: '个人中心',
    loginLogs: '登录日志',
    deviceLogs: '设备日志',
    system: '系统首页',
    permission: '权限首页',
    corporationManage: '公司管理',
    subAccout: '子账号管理',
    subAccoutDetail: '子账号详情',
    dealer: '经销商管理',
    dealerDetail: '经销商详情',
    afterSales: '售后首页',
    device: '设备首页',
    deviceList: '设备列表',
    deviceDetail: '设备详情',
    productList: '产品列表',
    productiDetail: '产品详情',
    miniProgramConfig: '微信小程序配置',
    errorManage: '故障管理',
    engineerManage: '工程管理',
    engineerDetail: '工程详情',
    map: '设备地图',
    ownership: '设备归属',
    btIotPlatform: '佰特IoT平台',
  }
}

export default zh;